@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
*::-webkit-scrollbar-track,
*::-webkit-scrollbar-corner {
  background: transparent;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: linear-gradient(45deg, #7444ff, #9b7bf2);
  border-radius: 50px;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(45deg, #8358ff, #b9a0ff);
}

@layer base {
  body {
    @apply font-body rtl:font-arabic;
  }

  .light body {
    background: #fff !important;
    color: #000;
  }

  .text-black {
    color: rgba(0, 0, 0, 0.6);
  }
}

@layer components {
  .modal {
    display: none;
    height: 100%;
    outline-width: 0;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 50;
  }

  .modal.show {
    background: linear-gradient(rgb(19 23 64 / 69%), rgb(19 23 64 / 70%));
    display: flex;
    justify-content: center;
    align-items: center;
  }

  select {
    -webkit-print-color-adjust: exact;
    -webkit-appearance: none;
    background-image: url('/images/down-arrow.png');
    background-position: right 0.5rem center;
    background-repeat: no-repeat;
    background-size: 0.7em;
    /* color-adjust: exact; */
    padding: 0 2.5rem 0 1rem;
  }

  .font-body[dir='rtl'] .font-display {
    @apply font-arabic font-bold;
  }

  [dir='rtl'] .font-display {
    @apply font-arabic font-bold;
  }

  .modal {
    @apply fixed left-0 top-0 z-50 h-full w-full overflow-y-auto overflow-x-hidden outline-0;
  }

  .modal-title {
    @apply ltr:!font-display text-jacarta-700 text-xl font-semibold leading-normal dark:text-white;
  }
 
  /* Article */
  .article-content > *:not(.article-content-wide) {
    @apply mx-auto mt-6 max-w-[48.125rem];
  }

  .article-content > h1,
  .article-content > h2,
  .article-content > h3,
  .article-content > h4,
  .article-content > h5,
  .article-content > h6 {
    @apply text-jacarta-700 font-display !mt-12 mb-4 dark:text-white;
  }

  .article-content > p {
    @apply dark:text-jacarta-300;
  }
}

@layer utilities {
  .hero-img {
    border-radius: 32%;
  }

  .js-page-header--is-sticky {
    background-color: hsla(0, 0%, 100%, 0.5);
  }

  .js-page-header--is-sticky.page-header--transparent,
  .dark .js-page-header--is-sticky {
    background-color: rgba(19, 23, 64, 0.5);
  }

  .nav-menu--is-open {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    opacity: 1;
    overflow-y: auto;
    padding: 0 1.5rem 1.5rem;
    visibility: visible;
  }
  .rounded-2xl {
    border-radius: 1.25rem;
  }
  .rounded-xl {
    border-radius: 0.75rem;
  }

  .show-accordion .accordion-collapse {
    display: block !important;
  }
}

@media (min-width: 1700px) {
  .h-1527 {
    height: 88vh;
  }
}

.select-rtl {
  direction: rtl;
  -webkit-appearance: none; /* Remove default arrow in Webkit browsers */
  -moz-appearance: none; /* Remove default arrow in Mozilla browsers */
  background-image: url('/images/svg/drop-down.svg');
  background-repeat: no-repeat;
  background-position: 10px center; /* Adjust space from left */
  background-size: 15px; /* Adjust the size of your arrow */
  padding-left: 30px; /* Adjust padding to make space for the arrow */
}

.rtl {
  direction: rtl;
}

.phone-rtl .flag-dropdown {
  left: auto; /* Reset the left positioning */
  right: 0; /* Place the dropdown on the right */
}

.phone-rtl .selected-flag {
  float: right; /* Position the selected flag on the right */
}

.phone-rtl input {
  padding-left: 30px; /* Adjust the padding to provide space for the country code */
  padding-right: 10px;
}

.force-ltr {
  direction: ltr !important;
}

.flex-center {
  @apply flex items-center justify-center;
}
.menu-icon-button {
  @apply dark:text-jacarta-50 text-jacarta-800 dark:border-jacarta-600 border-jacarta-100 bg-white font-semibold shadow-sm hover:bg-accent hover:text-white hover:opacity-100 dark:bg-transparent dark:hover:bg-accent;
}
.menu-gradient {
  @apply dark:from-jacarta-600 dark:to-jacarta-700 to-jacarta-100 bg-gradient-to-br from-white;
}
.menu-gradient-50 {
  @apply dark:from-jacarta-600/50 dark:to-jacarta-700/50 to-jacarta-100/50 bg-gradient-to-br from-white/50;
}
.menu-gradient-invert {
  @apply from-jacarta-600 to-jacarta-700 dark:to-jacarta-100 bg-gradient-to-br dark:from-white;
}
.menu-gradient-invert-50 {
  @apply from-jacarta-600/50 to-jacarta-700/50 dark:to-jacarta-100/50 bg-gradient-to-br dark:from-white/50;
}

label:has(+ input:required)::after,
label.required::after {
  content: '*';
  @apply inline-block -translate-y-0.5 px-1 text-sm text-red-500;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.react-tel-input .country-list .country.highlight {
  @apply dark:!bg-jacarta-600;
}
